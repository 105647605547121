<!--
 * @Author: zhongxq
 * @Date: 2023-10-19 22:57:41
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-27 20:26:03
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\collection\index.vue
 * @Description: 剧目典藏
 *
-->
<template>
  <div
    class="fixed-page culture-collection-index"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="200"
  >
    <Breadcrumb class="breadcrumb-1400" />
    <div :class="`container ${bgClass}`">
      <div class="input-box">
        <el-input
          v-model="search"
          class="input"
          placeholder="请输入搜索内容"
          @change="handleSearch"
        >
          <template slot="append">
            <span @click="handleSearch">搜索</span>
          </template>
        </el-input>
      </div>
      <h1 class="title">人艺剧目典藏</h1>
      <p class="desc">Repertory Of Human Art</p>
      <div class="date-list">
        <div
          v-for="dateItem in dateList"
          :key="dateItem.year"
          class="date-list-item"
          :id="`year-${dateItem.year}`"
        >
          <div class="date">{{ dateItem.year }}</div>
          <ul
            v-if="dateItem.children && dateItem.children.length"
            class="list"
            :style="`height: ${
              (2 * dateItem.children.length + 1) * 3.6458
            }vw;`"
          >
            <li
              v-for="item in dateItem.children"
              :key="item.id"
              class="list-item"
            >
              <div class="left">
                <c-image :src="item.image" />
              </div>
              <div class="right">
                <div class="info">
                  <p class="line-name">{{ item.repertoireName }}</p>
                  <p class="line-info" v-html="item.descript"></p>
                  <p class="line-tag">
                    <span class="tag">{{
                      item.showTime
                        ? dayjs(item.showTime).format("YYYY-MM-DD")
                        : ""
                    }}</span>
                    <span>
                      <c-image
                        :src="require('@/assets/img/performance/right.png')"
                        @click="handleDetail(item)"
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div class="float-line">
                <div class="dot"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Year />
    <PageFooter />
  </div>
</template>

<script>
import { getTimeLine } from "@/service/performance";
import dayjs from "dayjs";
import Year from "./components/Year.vue";

export default {
  components: { Year },
  data() {
    return {
      dayjs,
      search: "",
      pager: {
        pageNo: 1,
        pageSize: 99999,
      },
      hasNextPage: true,
      dateList: [],
      timer: null,
      listCount: 0,
    };
  },
  created() {
    this.handleSearch();
  },
  computed: {
    bgClass() {
      if (this.listCount > 8) {
        return "bg-2";
      }
      if (this.listCount > 2) {
        return "bg-1";
      }
      return "";
    },
  },
  methods: {
    async getData() {
      try {
        const res = await getTimeLine({
          ...this.pager,
          condition: { search: this.search },
        });
        if (res.data) {
          const data = res.data.data || [];
          let list = [...this.dateList];

          if (this.pager.pageNo === 1) {
            list = data;
            this.listCount = 0;
          } else {
            list = list.concat(data);
          }
          // 是否有更多
          if (res.data.totalPage <= this.pager.pageSize) {
            this.hasNextPage = false;
            this.listCount = res.data.totalCount;
          } else {
            this.listCount += this.pager.pageSize;
          }
          // 过滤
          let arr = list.map((item) => item.year);
          arr = [...new Set(arr)];
          if (arr.length < list.length) {
            list = arr.map((year) => {
              let children = [];
              list
                .filter((item) => item.year === year)
                .forEach((it) => {
                  children = children.concat(it.children || []);
                });
              return { year, children };
            });
          }
          this.dateList = list;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.dateList = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 500);
    },
    handleDetail(item) {
      window.open(`#/culture/collection/detail/${item.id}?from=dc`);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-collection-index {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    background-color: #ffffff;
    margin: 0 auto 2.6042vw;
    width: 72.9167vw;
    padding: 1.9792vw 0;
    box-sizing: border-box;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100% auto, 100% auto;
    background-position: 0 15.625vw, bottom right;
    &.bg-1 {
      background-image: url("~@/assets/img/performance/bg-2.png");
    }
    &.bg-2 {
      background-image: url("~@/assets/img/performance/bg-2.png"),
        url("~@/assets/img/performance/bg-3.png");
    }
    .input {
      width: 56.25vw;
      height: 3.3333vw;
      background: #f8f8fa;
      border-radius: 0.3125vw;
      .el-input__inner {
        height: 100%;
        background: #f8f8fa;
        border: none;
        font-size: 0.8333vw;
        &::placeholder {
          color: #909399;
        }
      }
      .el-input-group__append {
        border: none;
        width: 8.2292vw;
        height: 3.3333vw;
        background: #f9680d;
        border-radius: 0 0.3125vw 0.3125vw 0;
        color: #ffffff;
        text-align: center;
        font-size: 1.0417vw;
        font-weight: 600;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          height: 3.3333vw;
          line-height: 3.3333vw;
        }
      }
    }
    .title {
      font-size: 2.6042vw;
      font-family: Qiuhongkai;
      font-weight: 800;
      color: #212122;
      margin: 3.125vw 0 0.625vw;
    }
    .desc {
      font-size: 1.0417vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #909399;
      letter-spacing: 0.1563vw;
    }
  }
  .date-list {
    margin-top: 2.0833vw;
    .date-list-item {
      .date {
        margin: auto;
        width: 11.9792vw;
        height: 3.125vw;
        text-align: center;
        line-height: 3.125vw;
        font-size: 1.0417vw;
        font-weight: 800;
        color: #ffffff;
        .background-image("/performance/colorbg.png");
        background-size: 100% 100%;
        position: relative;
      }
      & + .date-list-item {
        margin-top: 2.0833vw;
        .date::before {
          content: "";
          width: 0;
          height: 7.0313vw;
          border-left: 0.0521vw dashed #f9680d;
          position: absolute;
          bottom: 3.125vw;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
    }
  }
  .list {
    padding: 0;
    margin: 2.0833vw auto 0;
    list-style: none;
    width: 68.75vw;
    box-sizing: border-box;

    .list-item {
      width: 27.0833vw;
      height: 10.9375vw;
      box-sizing: border-box;
      position: relative;
      float: left;
      margin-top: 3.6458vw;
      .float-line {
        position: absolute;
        right: -7.2917vw;
        top: 50%;
        transform: translateY(-50%);
        .dot {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 1.0417vw;
          height: 1.0417vw;
          border-radius: 1.0417vw;
          background: #ffffff;
          border: 0.0521vw solid #f9680d;
          z-index: 3;
          &::before {
            content: "";
            width: 0;
            height: 6.25vw;
            border-left: 0.0521vw dashed #f9680d;
            position: absolute;
            bottom: 1.0417vw;
            left: 0.5208vw;
            z-index: 1;
          }
        }
        &::before {
          content: "";
          width: 0.3646vw;
          height: 0.3646vw;
          border-radius: 0.3646vw;
          background: #f9680d;
          position: absolute;
          bottom: -0.1563vw;
          right: 5.9375vw;
          z-index: 2;
        }
        &::after {
          content: "";
          width: 5.4167vw;
          height: 0;
          border-top: 0.0521vw dashed #f9680d;
          position: absolute;
          bottom: 0;
          right: 0.5208vw;
          z-index: 1;
        }
      }
      &:nth-child(1) {
        margin-top: 0;
        .float-line .dot::before {
          height: 7.2917vw;
        }
      }
      &:nth-child(2) {
        margin-top: 7.2917vw;
      }
      &:nth-child(2n) {
        float: right;
        .float-line {
          left: -7.2917vw;
          right: unset;
          &::before {
            left: 5.9375vw;
            right: unset;
          }
          &::after {
            left: 0.5208vw;
            right: unset;
          }
        }
      }
      .left {
        position: absolute;
        left: 0;
        top: 0;
        width: 7.0833vw;
        height: 10.4167vw;
        box-shadow: 0.5208vw 0.5208vw 1.0417vw 0 rgba(0, 0, 0, 0.12);
        border-radius: 0.2083vw;
        z-index: 2;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 0.2083vw;
          display: block;
        }
        .tag {
          position: absolute;
          left: 0.2083vw;
          top: 0.2083vw;
          height: 1.0417vw;
          background: #bf805f;
          border-radius: 0.1042vw;
          line-height: 1.0417vw;
          font-size: 0.625vw;
          color: #ffffff;
          padding: 0 0.4167vw;
        }
      }
      .right {
        position: absolute;
        top: 0.5208vw;
        left: 0;
        height: 10.4167vw;
        width: 100%;
        background-color: #f8f8fa;
        .background-image("/performance/bg-1.png");
        background-position: right top;
        background-repeat: no-repeat;
        .info {
          box-sizing: border-box;
          height: 100%;
          padding: 1.0417vw 1.5625vw 1.0417vw 8.6458vw;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .line {
            &-name {
              font-size: 1.0417vw;
              font-weight: 800;
              color: #212122;
            }
            &-tag {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              .tag {
                font-size: 0.625vw;
                font-weight: 400;
                color: #212122;
                background: #f8d39f;
                border-radius: 0.1042vw;
                height: 1.0417vw;
                line-height: 1.0417vw;
                padding: 0 0.4167vw;
                margin-right: 0.625vw;
              }
              .img {
                width: 3.0208vw;
                height: 0.7813vw;
                cursor: pointer;
                background-color: #f9680d;
                border-radius: 0.7813vw;
              }
            }
            &-info {
              font-size: 0.7292vw;
              font-weight: 400;
              color: #909399;
              .text-overflow(3);
            }
          }
        }
      }
    }
  }
}
</style>
