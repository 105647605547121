<template>
  <div id="ruler">
    <ul class="ul-list">
      <li
        v-for="it in dayjs().year() - 1949"
        :key="it"
        :class="{ long: (dayjs().year() - it + 1) % 10 === 0 }"
        :id="`left-${it}`"
        @click="handleCheck(it)"
      >
        <span :class="['year', { 'is-checked': checked === it }]">{{
          dayjs().year() - it + 1
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      dayjs,
      checked: 0,
    };
  },
  methods: {
    handleCheck(it) {
      this.checked = it;
      // 左侧年份
      let domLeft = document.querySelector(`#left-${it}`);
      domLeft?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // 中间内容
      let dom = document.querySelector(`#year-${dayjs().year() - it + 1}`);
      dom?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="less" scoped>
#ruler {
  position: fixed;
  left: 1.0417vw;
  top: 50%;
  transform: translateY(-50%);
  height: 60vh;
  overflow-y: auto;
  z-index: 100;
  direction: rtl;
  &::-webkit-scrollbar {
    // width: 6px;
    display: none;
  }
  // &::-webkit-scrollbar-track {
  //   width: 6px;
  //   background-color: transparent;
  //   border-radius: 10px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   width: 6px;
  //   background-color: rgba(249, 104, 13, 0.6);
  //   border-radius: 10px;
  //   max-height: 100px;
  // }
  .ul-list {
    direction: ltr;
    margin: 0;
    padding: 0.5208vw;
    list-style: none;
    li {
      position: relative;
      height: 0.8333vw;
      display: flex;
      align-items: center;
      .year {
        display: inline-block;
        opacity: 0;
        line-height: 0.8333vw;
        margin-left: 1.5625vw;
        font-size: 0.8333vw;
        font-weight: 400;
        color: #909399;
      }
      &:hover > .year,
      .year.is-checked {
        opacity: 1;
        padding: 0 0.4688vw;
        height: 1.1458vw;
        line-height: 1.1458vw;
        background: #21424b;
        font-weight: 800;
        color: #ffffff;
        border-radius: 0.1042vw;
        position: relative;
        &::before {
          content: "";
          width: 0;
          left: 0;
          border: 0.4167vw solid transparent;
          border-right-color: #21424b;
          position: absolute;
          left: -0.625vw;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &::before {
        content: "";
        width: 0.5208vw;
        height: 0.0521vw;
        background: #dee0e5;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.long {
        .year {
          opacity: 1;
        }
        &::before {
          width: 1.0417vw;
          background: #aeb3bb;
        }
      }
    }
  }
}
</style>
